function searchModules() {
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById('search-modules');
        filter = input.value.toUpperCase();
        ul = document.getElementById("myUL");
        li = ul.getElementsByTagName('li');
        for (i = 0; i < li.length; i++) {
          a = li[i].getElementsByTagName("a")[0];
          txtValue = a.textContent || a.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            li[i].style.display = "";
          } else {
            li[i].style.display = "none";
          }
        }
}


function clearSearch() {
  searchModules();
}

function handleInput() {
  var inputElement = document.getElementById("search-modules");
  if (inputElement.value === "") {
      clearSearch();
  }
}
function searchModule() {
  const arr = []
  const input = document.querySelector("#search-modules")
  const filter = input.value.toUpperCase();
  const topUl = document.getElementById("myUL");
  const topLi = topUl.getElementsByTagName('li'); 
  topLi.forEach(el=>arr.push(el))   
  const ul = document.getElementById("overflow-menu");
  const secondLi = ul.querySelectorAll(".without-actions")
  secondLi.forEach(el => arr.push(el));
  for (i = 0; i < arr.length; i++) {
      a = arr[i].getElementsByTagName("a")[0];
      if (a.innerHTML.toUpperCase().indexOf(filter) > -1) {
          arr[i].style.display = "";
          console.log(arr[i]);
      } else {
          arr[i].style.display = "none";
      }
  }

}
